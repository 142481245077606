.container,
.containerColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 10px;
}

.alertText {
    color: red;
    animation: fadeIn 0.5s;
    padding: 5px 0px 0px 0px;
    margin: 0px;
}

.container > * + * {
    margin-top: 0.5rem;
}

.field {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid var(--blue);
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: 40px;
    font-size: 1rem;
    line-height: 2.15;
}

.invalidField {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid red;
    box-shadow: 0 0 0 .25rem rgba(255,0,0,.25);
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: 40px;
    font-size: 1rem;
    line-height: 2.15;
}

.invalidField:focus {
    border: 1px solid red;
    background-color: #fff;
    outline: 0;
}

.field:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
}

.field:disabled, .field[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.label {
    color: var(--blue);
    font-weight: 500;
}

.input {
    width: 100%;
}

.text {
    color: "#212529";
}

@media (min-width: 1024px) {
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    .containerColumn {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .mainContainer {
        padding: 0px 20px;
    }

    .container > * + * {
        margin-left: 1rem;
        margin-top: 0px;
    }

    .label {
        white-space: nowrap;
    }

    .field, .invalidField {
        max-width: 200px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0%;
    };
    100% {
        opacity: 100%;
    }
}