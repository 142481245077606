.icast-logo {
    width: 58px;
    height: 40px;
    margin-left: 10px;
}

.icast-nav {
    margin-bottom: 25px;
    background-color: white;
}

.MainHeader {
    flex: 0 0 auto;
}

.icast__logo-span1 {
    color: var(--blue);
    font-weight: 600;
}

.icast__logo-span2 {
    color: var(--blue);
    text-align: center !important;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    /* border-color: var(--blue);
    border-width: 0px 0px 2px 0px;
    border-style: solid; */
    background-color: white;
    padding: 5px 5px;
    z-index: 99;
}

@media (min-width: 1024px){
    .header {
        /* height: 80px; */
        padding: 15px 10px;
    }
}