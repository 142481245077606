table {
    border: 3px solid var(--blue);
    /* border-radius: 20px; */
    padding: 50px 5px 25px 5px;
    width: 100%;
    border-collapse: collapse;
    background-color: white;
}

th,
td {
    text-align: left;
    padding: 8px;
    border: 1px solid var(--blue);
    width: 65%;
}

@media (min-width: 1024px) {
    th,
    td {
        width: 80%;
    }
}

th {
    background-color: #e2e8f0;
    /* background-color: #cbd5e1; */
    /* background-color: var(--light-grey); */
    color: var(--blue);
}

.table__mobile-view {
    overflow-x: auto;
}

.nav-tabs .nav-link {
    margin-left: 10px;
    margin-right: 10px;
    height: 45px;
    width: 145px;
    background-color: var(--blue);
    color: white;
    border-radius: 10%;
    font-weight: bold;
    font-size: 12px;
    transition: all 0.3s;
    padding: 10px;
}

.nav-tabs .nav-link:hover {
    scale: 1.1;
}

.nav-tabs {
    border-bottom: 0px solid transparent;
}
.nav-tabs .nav-link {
    border: 0px solid transparent;
}

.nav-tabs .nav-link.active {
    font-weight: bold;
    font-size: 12px;
    box-shadow: 2px 2px 5px 5px #888888;
    background-color: rgb(250, 185, 35);
    border-color: #fdb813;
    color: white;
}

.nav-tabs li {
    text-decoration: none;
}

.icast-nav-tabs {
    margin-bottom: 5%;
}

.tabs__heading {
    text-align: center;
    font-weight: bold;
    /* text-shadow: 2px 2px #888888; */
    text-transform: uppercase;
    /* margin-bottom:25px; */
    margin-left: 30px;
    margin-top: 5%;
}

.mb-3 {
    margin-left: 25px;
    display: flex;
}

.form-label {
    margin-right: 15px;
    margin-top: 1%;
    font-weight: 400;
}

.icast-submitbtn {
    margin-left: 20px;
    width: 96px;
    background-color: var(--blue);
    margin-bottom: 25px;
}
.icast-submitbtn:hover {
    background-color: rgb(253, 184, 19);
    color: white;
    border-color: #fdb813;
}
.icast__header-pos {
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
}

.modalButton {
    background-color: var(--blue);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
@media screen and (max-width: 767px) {
    .modalButton {
        background-color: var(--blue);
        height: 40px;
        width: 155px;
        padding: 0;
    }
    .bottom__box {
        border-radius: 0.375rem;
    }
}

.bottom__box p,
.bottom__box span {
    display: inline-block;
    vertical-align: middle;
}

.bottom__box p {
    margin-top: 15px;
}

.modalButton {
    display: inline-block;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    color: var(--blue);
    margin-left: 5px;
    text-decoration: solid;
    font-weight: bold;
    font-style: italic;
    font-size: large;
}

.modalButton:hover {
    background-color: transparent;
    color: var(--blue);
}

.bottom__box-span {
    font-size: medium;
}

.bottom__box {
    margin-bottom: 5%;
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.input__alert{
    background:none;
    border:none;
    color:rgb(236, 8, 8);
    font-size:smaller;
    
}
/* Media query for mobile devices with screen width up to 480px */
@media only screen and (max-width: 1024px) {
    .bottom__box p {
        margin: 0;
    }

    .bottom__box-span {
        font-size: small;
    }

    .bottom__box a {
        display: block;
        margin-top: 13px;
    }

    .bottom__box {
        /* border:1px solid var(--blue); */
        /* background-color: #f7f8f8; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        padding: 0px 10px;
    }

    .modalButton {
        margin-left: 0px;
    }
}
