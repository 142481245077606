.heading {
    font-size: medium;
    text-align: left;
    align-self: flex-start;
    color: var(--blue);
    margin-bottom: 10px;
}

.tabs {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-flow: column;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 5px;
}

.container {
    position: fixed;
    top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    background-color: var(--light-grey);
    z-index: 99;
}

.containerEmbedded {
    position: fixed;
    top: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    background-color: var(--light-grey);
    z-index: 99;
}

.navBar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 10px 5px 20px 5px;
    background-color: var(--light-grey);
}

.tabButton {
    height: 100%;
    width: 100%;
    border-color: var(--blue);
    color: var(--blue);
    background-color: var(--light-grey);
    /* border-radius: 9999px; */
    border-radius: 0.375rem;
    font-weight: bold;
    font-size: 12px;
    transition: all 0.3s;
    padding: 10px 3px;
}

.tabButtonActive{
    height: 100%;
    width: 100%;
    color: white;
    /* border-radius: 9999px; */
    border-radius: 0.375rem;
    font-weight: bold;
    font-size: 12px;
    transition: all 0.3s;
    padding: 10px 3px;
    background-color: var(--blue);
    border-color: var(--blue);
}

.tabButton:hover {
    scale: 1.05;
}

@media (min-width: 1024px){
    .heading {
        font-size: large;
        align-self: center;
        margin-right: 1rem;
        margin-bottom: 0px;
        white-space: nowrap;
    }

    .tabs {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        /* width: fit-content; */
        gap: 0px;
    }

    .container {
        top: 70px;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .containerEmbedded {
        top: 0px;
        flex-direction: column;
        align-items: center;
        width: 100%
    }

    .navBar {
        flex-direction: row;
        width: 90%;
        padding: 10px 20px;

    }

    .tabButton {
        /* margin-left: 10px;
        margin-right: 10px; */
        height: 45px;
        /* width: 145px; */
        padding: 10px;
    }

    .tabButtonActive{
        /* margin-left: 10px;
        margin-right: 10px; */
        height: 45px;
        /* width: 145px; */
        padding: 10px;
    }

    .tabs > * + * {
        margin-left: 0.5rem;
    }
}

@media (min-width: 1280px) {
    .navBar {
        width: 80%;
    }
}

@media (min-width: 1536px) {
    .navBar {
        width: 70%;
    }
}

@media (min-width: 1920px) {
    .navBar {
        width: 60%;
    }
}