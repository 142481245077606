.columnWidth {
    width: 65%;
}

.heading {
    font-size: medium;
    color: var(--blue);
    font-weight: bold;
}

.groupContainer {
    background-color: #f3f4f6;
    border-radius: 0.375rem;
    margin-top: 5px;
}

.group {
    padding: 10px;
}

.fieldsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.fieldsContainer > * + * {
    margin-top: 1rem;
}

.main {
    padding: 0px;
    animation: fadeIn 0.3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0%;
    };
    100% {
        opacity: 100%;
    }
}

.hidden {
    visibility: hidden;
    display: none;
}

@media (min-width: 1024px) {
    .group {
        padding: 10px 20px;
    }

    .columnWidth {
        width: 80%;
    }

    .fieldsContainer {
        display: flex;
        flex-direction: row;
        align-items: start;
        width: fit-content;
    }

    .fieldsContainer > * + * {
        margin-bottom: 0;
        margin-top: 0;
    }

    .main {
        padding-bottom: 20px;
    }
}