.heading {
    font-size: large;
    color: var(--blue);
    font-weight: bold;
    margin: 0;
}

.text {
    padding: 0;
    margin: 0;
    font-size: small;
}

.textContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
}

.button {
    background-color: white;
    border: 0px;
    padding: 0px 5px;
    /* font-style: italic; */
    color: var(--blue);
    font-size: small;
}

.fieldsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.fieldsContainer > * + * {
    margin-top: 0.5rem;
}

.twoColumnsfieldContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.twoColumnsfieldContainer > * + * {
    margin-top: 0.5rem;
}

@media (min-width: 1024px) {
    .twoColumnsfieldContainer {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 8px;
    }

    .twoColumnsfieldContainer > * + * {
        margin-top: 0;
    }

    .textContainer {
        padding: 0px 20px;
    }

    .button {
        font-size: medium;
    }  
    
    .text {
        font-size: medium;
    }
}