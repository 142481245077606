:root {
  --blue: #006EB6;
  --light-grey: #f1f5f9;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

p {
    font-size: small;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select {
    -webkit-appearance: menulist-button;
    color: #212529;
}

.form-control {
    border-color: var(--blue);
    height: 40px;
    font-size: 1rem;
    line-height: 2.15;
}

.form-select {
    border-color: var(--blue);
    height: 40px;
}

.btn-close {
    color: var(--blue);
    padding: 20px;
    margin: 20px;
}

.btn-primary {
    background-color: var(--blue);
}

.btn-primary:hover {
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
}

.modal-header {
    background-color: #e2e8f0;
}

@media (min-width: 1024px)  {
    .form-control {
        max-width: 200px;
    }
    
    .form-select {
        max-width: 200px;
    }

    table .form-select {
        max-width: 100%;
    }
}

.app-container {
    position: relative;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Main {
    display: flex;
    flex-direction: column;
    min-height: 94vh;
    width: 615px;
}

.Main .MainContent {
    flex: 1 0 auto;
    width: 516px;
}
.py-5 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
}

@media (min-width: 576px) {
    .container,
    .container-sm {
        max-width: 540px;
    }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    margin-left: auto;
    margin-right: auto;

    width: 100%;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}