.container {
    margin-top: 150px;
    margin-bottom: 20px;
}

.containerEmbedded {
    margin-top: 100px;
    margin-bottom: 20px;
}

@media (min-width: 1024px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 140px;
    }

    .containerEmbedded {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 70px;
    }

    .subContainer {
        width: 90%;
    }
}

@media (min-width: 1280px) {
    .subContainer {
        width: 80%;
    }
}

@media (min-width: 1536px) {
    .subContainer {
        width: 70%;
    }
}

@media (min-width: 1920px) {
    .subContainer {
        width: 60%;
    }
}
